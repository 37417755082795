import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import styled from "styled-components";
import {
  mainWhite,
  mainNavy,
  innerWidth,
  mainViolet,
  screen,
} from "../../components/common/variables";
import Nav from "../../components/nav";
import StyledButton from "../../components/styled-button";
import ProgressBar from "../../components/progress-bar";
import BottomNav from "../../components/bottom-nav";
import { motion } from "framer-motion";

// motion framer animation props value
const variantsContainer = {
  open: { opacity: 1 },
  // close: { opacity: 0.99 },
};

const variantsList = {
  open: {
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.07,
    },
  },
  close: {
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1,
    },
  },
};

const variantsItem = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  close: {
    y: 29,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const Wrapper = styled(motion.div)`
  background: ${mainNavy};
  position: relative;
  overflow: auto;
  color: ${mainWhite};
  height: calc(100vh - 96px);
  padding: 0 0 50px 0;

  .inner-wrapper {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding: 0 32px;
    display: flex;
    @media ${screen.xsmall} {
      padding: 140px 48px 0 48px;
    }
    @media ${screen.medium} {
      padding: 140px 0 0 0;
    }

    .texts {
      margin: 0 70px 0 0;
      max-width: 700px;
      width: 100%;

      h1 {
        color: ${mainViolet};
        font-size: 2.3rem;
        font-weight: 700;
        margin: 0 0 18px 0;
      }

      p {
        font-size: 1.12rem;
        margin: 0 0 16px 0;
      }

      ul {
        padding: 0 0 0 50px;
        li {
          list-style-type: disc;
          font-size: 1.12rem;
          margin: 0 0 14px 0;
        }
      }
    }

    .btns {
      margin: 82px 0 0 50px;
      &__each {
        margin: 0 0 14px 0;

        a {
          background: none;
          border: 2px #fff solid;
          @media ${screen.withCursor} {
            &:hover {
              background: ${mainViolet};
              border: 2px ${mainViolet} solid;
              color: #fff;
            }
          }
        }
      }
    }
  }
`;

const DiscoverOne = () => {
  return (
    <Layout>
      <SEO title="Discover - 1" />
      <Nav />
      <Wrapper initial="close" animate={"open"} variants={variantsContainer}>
        <motion.div variants={variantsList}>
          <ProgressBar>
            <h2 className="title">DISCOVER</h2>
            <div className="bars">
              <p className="selected">1. Import</p>
              <p className="selected selected--not-selected">2. Check</p>
              <p className="selected selected--not-selected">3. Report</p>
            </div>
          </ProgressBar>

          <div className="inner-wrapper">
            <motion.div variants={variantsItem} className="texts">
              <h1>I want to import my applications from…</h1>
              <ul>
                <li>
                  Discover what applications are suitable candidates to onboard
                  into Windows Virtual Desktop in their current format.​
                </li>
                <li>
                  Discover what applications are suitable candidates to
                  modernize into the MSIX format.​
                </li>
                <li>Discover what applications need more attention.​</li>
              </ul>
            </motion.div>
            <motion.div variants={variantsItem} className="btns">
              <StyledButton to={"/discover/2/"} className="btns__each">
                MICROSOFT SCCM
              </StyledButton>
              <StyledButton to={"/discover/1/"} className="btns__each">
                A MANUAL UPLOAD
              </StyledButton>
            </motion.div>
          </div>

          <BottomNav
            leftLabel={"BACK"}
            leftLink={"/goals/"}
            rightLabel={"BACK TO START"}
            rightLink={"/"}
          />
        </motion.div>
      </Wrapper>
    </Layout>
  );
};

export default DiscoverOne;
